import React, { useContext, useEffect } from 'react'
import * as styles from './lightbox-image.module.scss'
import SiteContext from '../site-context/site-context'

const closeLightbox = (setLightboxImageSrc) => {
  return (event) => {
    if (event.key !== 'Escape') { return }
    setLightboxImageSrc(null)
  }
}

const LightboxImage = () => {
  const {lightbox: { lightboxImageSrc, setLightboxImageSrc }} = useContext(SiteContext)

  useEffect(() => {
    window.addEventListener('keydown', closeLightbox(setLightboxImageSrc))

    return () => {
      window.removeEventListener('keydown', closeLightbox())
    }
  }, [])

  return (
    <div className={`${styles.lightboxImage} ${!lightboxImageSrc ? '' : styles.open}`}>
      <button className={styles.bgClose} onClick={() => setLightboxImageSrc(null)} aria-label='Close Lightbox' />
      {
        lightboxImageSrc !== null &&
          <img src={lightboxImageSrc} alt='Lightbox Image' />
      }

      <button className={styles.closeBtn} onClick={() => setLightboxImageSrc(null)} aria-label='Close Lightbox'>
        <svg width='132' height='125' viewBox='0 0 132 125' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <rect x='20.8701' y='95.4594' width='97' height='21' transform='rotate(-45 20.8701 95.4594)' fill='#00072F'/>
          <rect x='89.4594' y='110.309' width='97' height='21' transform='rotate(-135 89.4594 110.309)' fill='#00072F'/>
        </svg>
      </button>
    </div>
  )
}

export default LightboxImage
