import React, { useContext } from 'react'
import * as styles from './trans-overlay.module.scss'
import SiteContext from '../site-context/site-context'

const TransOverlay = () => {
  const { transition: { pageTransition } } = useContext(SiteContext)

  return (
    <div className={`${styles.transOverlay} ${!pageTransition ? '' : styles.open}`} />
  )
}

export default TransOverlay
