import React from 'react'
import PageTrans from '../page-trans/page-trans'
import * as styles from './header.module.scss'

const convertToCharacters = (string) => {
  return string.split(' ').map((word, index) => {
    return (
      <span className={styles.logoWord} key={`logo-text--${index}`}>
        {
          word.split('').map((letter, index) => {
            return (
              <span key={`letter-${word}-${index}`}>{letter}</span>
            )
          })
        }
      </span>
    )
  })
}

const Header = ({ siteTitle }) => {

  return (
    <div className={styles.header}>
      <PageTrans fade to='/' className={styles.logoName}>{convertToCharacters(siteTitle)}</PageTrans>

      <ul>
        <li>
          <PageTrans fade to='/projects/1' className={styles.link}>Work</PageTrans>
        </li>

        <li>
          <PageTrans fade to='/blog/1' className={styles.link}>Blog</PageTrans>
        </li>
      </ul>
    </div>
  )
}

export default Header
