import React from 'react'
import PageTrans from '../page-trans/page-trans'
import * as styles from './footer.module.scss'

const Footer = ({ siteTitle }) => {
  return (
    <footer className={styles.footer}>
      <PageTrans to='/' className={styles.logoName}>
        <div>
          {[...Array(10)].map((_, index) => {
            return (
              <span className={styles.wordGroup} key={`footer-word--${index}`}>
                {siteTitle.split(' ').map((word, wordIndex) => {
                  return (
                    <span key={`footer-word-inner--${wordIndex}`}>{word}&nbsp;</span>
                  )
                })}
              </span>
            )
          })}
        </div>
      </PageTrans>

      <div className={styles.footerBar}>
        <p>© Copyright {new Date().getFullYear()} Matthew Fournier</p>
      </div>
    </footer>
  )
}

export default Footer
