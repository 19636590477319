import { graphql, StaticQuery } from 'gatsby'
import React, { useContext, useEffect } from 'react'
// import { checkPositions } from '../lib/animate-in'

import Header from '../components/header/header'
import '../../src/styles/main.scss'
import * as styles from './layout.module.scss'
import Footer from '../components/footer/footer'
import LightboxImage from '../components/lighbox-image/lightbox-image'
import TransOverlay from '../components/trans-overlay/trans-overlay'
import SiteContext from '../components/site-context/site-context'

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings {
      keywords
      title
      description
    }
  }
`

const Layout = (props) => {
  const { transition: { pageTransition } } = useContext(SiteContext)

  return (
    <StaticQuery
      query={query}
      render={data => {
        if (!data.site) {
          throw new Error('Missing "Site settings". Open the studio at http://localhost:3333 and add "Site settings" data')
        }
        return (
          <>
            <a className={styles.skipToContent} href='#siteContent' data-skip-to-content>
              Skip to content
            </a>
            <Header siteTitle={data.site.title} />
            <div className={`${styles.siteContent} ${!pageTransition ? '' : styles.siteContentTrans}`} id='siteContent'>
              {props.children}
            </div>
            <Footer siteTitle={data.site.title} />
            <LightboxImage />
            <TransOverlay />
          </>
        )
      }}
    />
  )
}

export default Layout
