import { navigate } from 'gatsby-link'
import React, { useContext } from 'react'
import SiteContext from '../site-context/site-context'
import * as styles from './page-trans.module.scss'

const PageTrans = ({ children, to, className }) => {
  const { transition: { setPageTransition } } = useContext(SiteContext)

  const changePage = (event) => {
    event.preventDefault()
    setPageTransition(true)
    setTimeout(() => {
      navigate(to)
    }, 500)

    setTimeout(() => {
      setPageTransition(false)
    }, 1000)
  }

  return (
    <a onClick={changePage} className={className} href={to}>
      {children}
    </a>
  )
}

export default PageTrans
