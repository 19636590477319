// extracted by mini-css-extract-plugin
export var hide = "trans-overlay-module--hide--TXbPX";
export var allHide = "trans-overlay-module--all--hide--vVlpl";
export var smallHide = "trans-overlay-module--small--hide--lQf+x";
export var mediumHide = "trans-overlay-module--medium--hide--YwGsb";
export var mediumDownHide = "trans-overlay-module--medium-down--hide--Pe0JX";
export var mediumUpHide = "trans-overlay-module--medium-up--hide--Yw6n8";
export var largeHide = "trans-overlay-module--large--hide--1WMOL";
export var xlargeHide = "trans-overlay-module--xlarge--hide---pSWC";
export var xxlargeHide = "trans-overlay-module--xxlarge--hide--fVnGF";
export var transOverlay = "trans-overlay-module--transOverlay--2iDpn";
export var open = "trans-overlay-module--open--i1Aal";