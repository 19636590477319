// extracted by mini-css-extract-plugin
export var hide = "footer-module--hide--FfWEy";
export var allHide = "footer-module--all--hide--c8rZ+";
export var smallHide = "footer-module--small--hide--u8Hw2";
export var mediumHide = "footer-module--medium--hide--mWeD9";
export var mediumDownHide = "footer-module--medium-down--hide--RnIP4";
export var mediumUpHide = "footer-module--medium-up--hide--Clezs";
export var largeHide = "footer-module--large--hide--d1GQf";
export var xlargeHide = "footer-module--xlarge--hide--O6DbB";
export var xxlargeHide = "footer-module--xxlarge--hide--ap-ey";
export var footer = "footer-module--footer--lU7l1";
export var logoName = "footer-module--logoName--aeLwI";
export var marquee = "footer-module--marquee--Gq7sl";
export var wordGroup = "footer-module--wordGroup--eoDhA";
export var footerBar = "footer-module--footerBar--Bt1GT";